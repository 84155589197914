<template>
  <div>
    <table
      class="cell-padding"
      width="100%"
      cellpadding="3"
      cellspacing="5"
    >
      <col style="width:15%">
      <col style="width:15%">
      <col style="width:15%">
      <col style="width:15%">
      <col style="width:15%">
      <thead>
        <tr>
          <th>{{ $tc('name', 1) }}</th>
          <th>{{ $tc('value') }}</th>
          <th>{{ $tc('addOnQuantity', 2) }}</th>
          <th>
            {{ $tc('addOnPrice', 2) }}
            <br />
            (RECURRENCIA)
          </th>
          <th>{{ $tc('total') }}</th>
        </tr>
      </thead>
      <tbody>
        <plan-feature-row
          v-for="(item, index) in data"
          :key="index"
          :company-service-frequency-billing="companyServiceFrequencyBilling"
          :feature.sync="item"
          @update-billed-value="onUpdateFeatureBilledValue(data, index, $event)"
        ></plan-feature-row>
      </tbody>
    </table>
  </div>
</template>
<script>

export default {
  name: 'BillFeature',
  components: {
    planFeatureRow: _ => import('@/views/company/plan-feature-row')
  },
  props: {
    companyServiceFrequencyBilling: {
      required: true
    },
    data: {
      required: true,
      default: []
    }
  },
  methods: {
    onUpdateFeatureBilledValue(features, index, value) {
      if (features && features.length > 0) {
        features[index].featureBilledValue = value
      }
    }
  }
}
</script>
